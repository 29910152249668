import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useApi } from "../hooks/useApi";
import LoadingPizza from "./LoadingPizza";

const OrderFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  box-sizing: border-box;
`;

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0.5rem 0;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const MenuItemDetails = styled.div`
  flex: 1;
  text-align: left;
`;

const MenuItemName = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
`;

const MenuItemDescription = styled.div`
  font-size: 0.85rem;
  color: #666;
`;

const MenuItemPrice = styled.div`
  font-size: 1rem;
  color: #000;
  @media (max-width: 600px) {
    order: 2;
    margin-top: 0.5rem;
  }
`;

const MenuItemQuantity = styled.input`
  width: 60px;
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;

  @media (min-width: 600px) {
    margin-top: 0;
    margin-left: 1rem;
  }

  @media (max-width: 600px) {
    order: 3;
    align-self: flex-end;
  }
`;

const TotalAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-top: 1rem;
`;

const TotalPrice = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  text-align: right;
`;

const SubmitButton = styled.button`
  background: #51535a;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-end;

  &:hover {
    background: #6b6d74;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`;

const HeaderTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
`;

const HeaderText = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 0.5rem;
`;

const HighlightText = styled.span`
  font-weight: bold;
  color: #333;
`;

interface OrderFormProps {
  menuId: string;
  timeSlotDate: string;
  initialOrder: { menuItemId: string; quantity: number }[];
  remainingSlots: number;
  onSubmit: (
    items: { menuItemId: string; quantity: number; price: string }[],
    total: string
  ) => void;
}

interface MenuItem {
  menuItemId: string;
  name: string;
  description: string;
  price: string;
}

const OrderForm: React.FC<OrderFormProps> = ({
  menuId,
  timeSlotDate,
  initialOrder,
  remainingSlots,
  onSubmit,
}) => {
  const { getMenu } = useApi();
  const [menu, setMenu] = useState<MenuItem[]>([]);
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [currentRemainingSlots, setCurrentRemainingSlots] =
    useState(remainingSlots);

  useEffect(() => {
    setIsLoading(true);
    getMenu(menuId).then((data) => {
      setMenu(data.items);
      const initialQuantities: { [key: string]: number } = {};
      data.items.forEach((item: MenuItem) => {
        const orderedItem = initialOrder.find(
          (i) => i.menuItemId === item.menuItemId
        );
        initialQuantities[item.menuItemId] = orderedItem
          ? orderedItem.quantity
          : 0;
      });
      setQuantities(initialQuantities);
      const initialTotalPrice = data.items.reduce(
        (total: number, item: { menuItemId: string; price: string }) => {
          const orderedItem = initialOrder.find(
            (i) => i.menuItemId === item.menuItemId
          );
          return (
            total +
            (orderedItem ? orderedItem.quantity * parseFloat(item.price) : 0)
          );
        },
        0
      );
      setTotalPrice(initialTotalPrice);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuId]);

  const handleQuantityChange = (menuItemId: string, quantity: number) => {
    const newQuantities = { ...quantities, [menuItemId]: quantity };
    const newTotalQuantity = Object.values(newQuantities).reduce(
      (sum, q) => sum + q,
      0
    );

    if (newTotalQuantity <= remainingSlots) {
      setQuantities(newQuantities);
      setCurrentRemainingSlots(remainingSlots - newTotalQuantity);

      const newTotalPrice = menu.reduce((total, item) => {
        return total + newQuantities[item.menuItemId] * parseFloat(item.price);
      }, 0);
      setTotalPrice(newTotalPrice);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const orderDetails = Object.entries(quantities)
      .filter(([_, quantity]) => quantity > 0)
      .map(([menuItemId, quantity]) => ({
        menuItemId,
        quantity,
        price: menu.find((menuItem) => menuItem.menuItemId === menuItemId)!
          .price,
      }));
    onSubmit(orderDetails, totalPrice.toFixed(2));
  };

  const totalOrderedQuantity = Object.values(quantities).reduce(
    (sum, quantity) => sum + quantity,
    0
  );

  const isSubmitDisabled =
    totalOrderedQuantity === 0 || totalOrderedQuantity > remainingSlots;

  if (isLoading) {
    return (
      <div>
        <LoadingPizza />
      </div>
    );
  }

  return (
    <OrderFormContainer>
      <Header>
        <HeaderTitle>Place Your Order</HeaderTitle>
        <HeaderText>
          Collection Time Slot: <strong>{timeSlotDate}</strong>
        </HeaderText>
        <HeaderText>
          Currently, in this collection time slot, there are{" "}
          <HighlightText>{currentRemainingSlots}</HighlightText> pizzas
          remaining.
        </HeaderText>
      </Header>
      <form onSubmit={handleSubmit}>
        {menu.map((item) => (
          <MenuItemContainer key={item.menuItemId}>
            <MenuItemDetails>
              <MenuItemName>{item.name}</MenuItemName>
              <MenuItemDescription>{item.description}</MenuItemDescription>
              <MenuItemPrice>£{item.price}</MenuItemPrice>
            </MenuItemDetails>
            <MenuItemQuantity
              type="number"
              min="0"
              max={remainingSlots}
              value={quantities[item.menuItemId]}
              onChange={(e) =>
                handleQuantityChange(
                  item.menuItemId,
                  parseInt(e.target.value) || 0
                )
              }
            />
          </MenuItemContainer>
        ))}
        <TotalAndButtonContainer>
          <TotalPrice>Total: £{totalPrice.toFixed(2)}</TotalPrice>
          <SubmitButton disabled={isSubmitDisabled} type="submit">
            Review Order
          </SubmitButton>
        </TotalAndButtonContainer>
      </form>
    </OrderFormContainer>
  );
};

export default OrderForm;
