import styled from "@emotion/styled";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faPhone, faPizzaSlice } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import OrderConfirmed from "../components/ConfirmedOrderForm";
import LoadingPizza from "../components/LoadingPizza";
import Modal from "../components/Modal";
import OrderForm from "../components/OrderForm";
import ReviewOrderForm from "../components/ReviewOrderForm";
import TimeSlotSelectionForm from "../components/TimeSlotSelectionForm";
import { useApi } from "../hooks/useApi";

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

const Logo = styled.img`
  max-width: 50%;
  height: auto;
  margin-bottom: 2rem;
  margin-top: 1rem;
  @media (min-width: 768px) {
    max-width: 25%;
  }
`;

const TradingDayList = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 2rem;
`;

const TradingDay = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid #ddd;
  margin-bottom: 0.5rem;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
`;

const OrderButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #51535a;
  font-size: 1.2rem;
  padding: 0;

  &:hover {
    color: #6b6d74;
  }

  &:focus {
    outline: none;
  }
`;

const IconContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
`;

const ContactIcon = styled.a`
  color: #51535a;
  font-size: 1.5rem;
  margin-top: 1rem;
  &:hover {
    color: #6b6d74;
  }
`;

const NoTradingDaysMessage = styled.div`
  text-align: center;
  font-size: 1.2rem;
  color: #51535a;
  margin-top: 2rem;
`;

const HomePage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState<string | null>(
    null
  );
  const [selectedTimeSlotDate, setSelectedTimeSlotDate] = useState<
    string | null
  >(null);
  const [selectedMenuId, setSelectedMenuId] = useState<string | null>(null);
  const [selectedTradingDayId, setSelectedTradingDayId] = useState<
    string | null
  >(null);
  const [remainingSlots, setRemainingSlots] = useState<number | null>(null);
  const [order, setOrder] = useState<{
    tradingDayId: string;
    tradingSlotId: string;
    items: {
      menuItemId: string;
      quantity: number;
      price: string;
      name: string;
      description: string;
    }[];
    total: string;
    name: string;
    email: string;
    phone: string;
    notes?: string;
  }>({
    tradingDayId: "",
    tradingSlotId: "",
    name: "",
    email: "",
    phone: "",
    items: [],
    total: "0",
  });
  const [orderNumber, setOrderNumber] = useState<number | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { fetchTradingDays, getMenu, postOrder } = useApi();
  const [tradingDays, setTradingDays] = useState<{
    page: number;
    totalPages: number;
    totalItems: number;
    items: {
      tradingDayId: string;
      date: string;
      tradingLocationId: string;
      tradingLocationName: string;
      menuId: string;
      start: string;
      close: string;
    }[];
  }>();

  useEffect(() => {
    fetchTradingDays(1, 5)
      .then((data) => {
        setTradingDays(data);
      })
      .catch((e) => {
        alert("Failed to fetch trading days");
        console.error(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTimeSlotSelect = (
    slotId: string,
    timeSlotDate: string,
    remaining: number
  ) => {
    setSelectedTimeSlotId(slotId);
    setSelectedTimeSlotDate(timeSlotDate);
    setRemainingSlots(remaining);
    setCurrentStep(2);
  };

  const handleOrderSubmit = async (
    items: { menuItemId: string; quantity: number; price: string }[],
    total: string
  ) => {
    const menuData = await getMenu(selectedMenuId!);
    const completeItems = items.map((item) => {
      const menuItem = menuData.items.find(
        (m: { menuItemId: string }) => m.menuItemId === item.menuItemId
      );
      if (!menuItem) {
        throw new Error(`Menu item with id ${item.menuItemId} not found`);
      }
      return {
        ...item,
        name: menuItem.name,
        description: menuItem.description,
      };
    });
    setOrder({
      tradingDayId: selectedTradingDayId!,
      tradingSlotId: selectedTimeSlotId!,
      items: completeItems,
      name: "",
      email: "",
      phone: "",
      total,
    });
    setCurrentStep(3);
  };

  const handleOrderConfirm = async (confirmedOrder: typeof order) => {
    setIsSubmitting(true);
    try {
      const orderForApi = {
        tradingDayId: confirmedOrder.tradingDayId,
        tradingSlotId: confirmedOrder.tradingSlotId,
        name: confirmedOrder.name!,
        email: confirmedOrder.email!,
        phone: confirmedOrder.phone!,
        notes: confirmedOrder.notes || "",
        items: confirmedOrder.items.map((item) => ({
          menuItemId: item.menuItemId,
          quantity: item.quantity,
          price: item.price,
        })),
        total: confirmedOrder.total,
      };

      setOrder({ ...order, ...confirmedOrder });

      const response = await postOrder(orderForApi);
      setOrderNumber(response.orderNumber);
      setCurrentStep(4);
    } catch (e) {
      console.error(e);
      alert("Failed to place the order.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const openModal = (menuId: string, tradingDayId: string) => {
    setSelectedTradingDayId(tradingDayId);
    setSelectedMenuId(menuId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentStep(1);
    setSelectedTimeSlotId(null);
    setSelectedTradingDayId(null);
    setRemainingSlots(null);
    setOrder({
      tradingDayId: "",
      tradingSlotId: "",
      name: "",
      email: "",
      phone: "",
      items: [],
      total: "0",
    });
  };

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {currentStep === 1 ? (
          <TimeSlotSelectionForm
            tradingDayId={selectedTradingDayId!}
            onSelect={handleTimeSlotSelect}
          />
        ) : currentStep === 2 ? (
          <OrderForm
            menuId={selectedMenuId!}
            timeSlotDate={selectedTimeSlotDate!}
            initialOrder={order.items}
            remainingSlots={remainingSlots!}
            onSubmit={handleOrderSubmit}
          />
        ) : currentStep === 3 ? (
          <>
            {isSubmitting && (
              <Overlay>
                <LoadingPizza />
              </Overlay>
            )}
            <ReviewOrderForm
              order={order}
              totalPrice={parseFloat(order.total)}
              timeSlotDate={selectedTimeSlotDate!}
              onConfirm={(updatedOrder) => handleOrderConfirm(updatedOrder)}
              onEdit={() => setCurrentStep(2)}
            />
          </>
        ) : currentStep === 4 ? (
          <OrderConfirmed
            order={order}
            timeSlotDate={selectedTimeSlotDate!}
            orderNumber={orderNumber!}
          />
        ) : null}
      </Modal>
      <HomePageContainer>
        <Logo src="/logo.png" alt="Logo" />

        {tradingDays && tradingDays.items.length > 0 ? (
          <>
            <p>Please select a location and date below to start your order</p>
            <TradingDayList>
              {tradingDays.items.map((item) => (
                <TradingDay
                  key={item.tradingDayId}
                  onClick={() => openModal(item.menuId, item.tradingDayId)}
                >
                  <span>
                    {item.tradingLocationName} -{" "}
                    {DateTime.fromISO(item.date).toLocaleString(
                      DateTime.DATE_MED_WITH_WEEKDAY
                    )}
                  </span>
                  <OrderButton
                    onClick={() => openModal(item.menuId, item.tradingDayId)}
                  >
                    <FontAwesomeIcon icon={faPizzaSlice} />
                  </OrderButton>
                </TradingDay>
              ))}
            </TradingDayList>
          </>
        ) : (
          <NoTradingDaysMessage>
            No events available at the moment. More coming soon!
          </NoTradingDaysMessage>
        )}
        <IconContainer>
          <ContactIcon href="tel:+447508940060">
            <FontAwesomeIcon icon={faPhone} />
          </ContactIcon>
          <ContactIcon
            href="https://www.instagram.com/pregocoffeeandpizza/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </ContactIcon>
        </IconContainer>
      </HomePageContainer>
    </>
  );
};

export default HomePage;
